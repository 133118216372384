@import "../../variables";

.landing {
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    transition: color .25s ease;

    .content{
        z-index: 2;
        padding: 0 50px;
    }

    .down-container {
        width: 100%;
        position: absolute;
        bottom: 40px;
        left: 0;
    }

    .down-container svg {
        height: 30px;
        display: none;
        margin: 0 auto;

        path{
            stroke: $text-color;
        }

        @include breakpoint(desktopWidth) {
            display: block;
        }

        @include breakpoint(wideDesktopWidth) {
            width: 50px;
        }

        @include breakpoint(giantDesktopWidth) {
            display: none;
        }
    }

    .landing {
        padding: 0 50px;
        position: relative;
    }

    .name {
        opacity: 0;
        transform: translateY(10px);
        margin-bottom: 10px;
        font-size: 3em;
        letter-spacing: .2em;
        font-family: 'Orbitron';
        font-weight: 400;
        color: $text-color;
        text-shadow: -1px 1px 10px $bgColor, 1px 1px 10px $bgColor, 1px -1px 10px $bgColor;
        transition: text-shadow .25s ease;
        animation: .5s ease-in-out .75s slideUp forwards;

        @include breakpoint(tabletWidth) {
            font-size: 4em
        }

        @include breakpoint(desktopWidth) {
            font-size: 5.5em
        }
    }

    .name span{
        transform: translateX(-10px);
    }

    .sub {
        opacity: 0;
        transform: translateY(10px);
        font-family: "Fraktion";
        font-weight: 400;
        font-size: 1.25em;
        color: $alt-text-color;
        animation: .5s ease-in-out 1s slideUp forwards;

        @include breakpoint(desktopWidth) {
            font-size: 1.5em
        }
    }

    .three{
        z-index: 1;
        position: absolute;
        left: 40%;
        transform: rotate(-10deg);
        filter: invert(1);
    }
}

@keyframes slideUp {
    from {
        transform: translateY(10px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}