@import '../../variables';

.canvas{
    display: none;
    background-color: $bgColor;
    position: absolute;
    right: 0;

    transition: background-color .25s ease;

    @include breakpoint(desktopWidth) {
        display: block;
    }
}

