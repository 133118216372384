@import '../../variables';

.projects {
    position: relative;
    list-style: none;
    padding: 25px 50px 50px 50px;
    margin-top: 10px;

    h1{
        color: $alt-text-color;
        text-transform: uppercase;
        font-size: 1.75em;
        margin-bottom: 20px;
    }

    ul{
        list-style: none;
        
        li{
            position: relative;
            width: 0;
            height: 2em;
            padding: 20px 0;
            border-bottom: 1px $alt-text-color solid;
            
            @include breakpoint(tabletWidth) {
                height: 2.5em;
            }
    
            @include breakpoint(desktopWidth) {
                height: 3em;
            }

            h2{
                position: absolute;
                transform: translateY(5px);
                opacity: 0;
            }
        }

        li:last-child{
            border: none;
        }

        .slideIn{
            animation: slideIn ease-in-out .5s forwards;
            animation-delay: calc(var(--n) * .5s);

            h2{
                animation: slideUp ease .75s forwards;
                animation-delay: calc(var(--n) * .5s + .5s);
            }
        }

        a{ 
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            font-family: "Orbitron";
            font-size: 1em;
            font-weight: 400;
            letter-spacing: .1em;
            color: $text-color;
            text-decoration: none;
            transition: color .25s ease;

            @include breakpoint(tabletWidth) {
                font-size: 1.5em;
            }
    
            @include breakpoint(desktopWidth) {
                font-size: 1.5em;
                letter-spacing: .15em;
            }
        }

        a:hover{
            color: $alt-text-color;
            transition: color .25s ease;
        }
    }
}

@keyframes slideIn {
    to {
        width: 100%;
    }
}

@keyframes slideUpProj {
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}