@import "../../variables";

.home{
  #pixi-container{
    display: none;

    @include breakpoint(wideTabletWidth){
      display: block;
      position: absolute;
      overflow: hidden;
      z-index: 0;
      margin-top: -50px;
      width: 100%;
      height: calc(100% + 50px);
      right: 0;
      top: 0;
    }
  }

  #pixi-container canvas{
    height: 100%;
  }
}