@import "../../variables";

.header{
    display: flex;
    align-items: center;
    padding-left: 50px;
    position: absolute;
    top: 30px;

    button{
        position: relative;
        height: 24px;
        width: 24px;

        @include breakpoint(tabletWidth){
            height: 32px;
            width: 32px;
        }
    }

    button .link{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        opacity: 1;
        transform: translateX(0);
        transition: transform .25s ease, opacity .25s ease;
    }

    button .hide{
        opacity: 0;
        transform: translateY(-10px);
        transition: transform .25s ease, opacity .25s ease;
    }

    button svg{
        width: 100%;
        height: 100%;

        path{
            stroke: $text-color;
        }
    }

    nav{
        a{
            margin-left: 50px;
            color: $alt-text-color;
            font-family: "Fraktion";
            font-weight: 400;
            font-size: 1.25em;
            letter-spacing: .1em;
            text-transform: uppercase;
        }
    }
}