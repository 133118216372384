//Colors

$bgColor: var(--background-color);
$text-color: var(--text-color);
$alt-text-color: var(--alt-text-color);
$gray: #D9D9D9;
$dark-gray: #808080;
$light-green: #E0FFDB;
$green: #B9FFAD;
$about-links: var(--about-links);
$brown: #726953;

//Breakpoints

@mixin breakpoint($point) {
    @if $point == giantDesktopWidth {
      @media (min-width: 2100px) { @content; }
    }
    @else if $point == wideDesktopWidth {
        @media (min-width: 1800px) { @content; }
      }
    @else if $point == desktopWidth {
      @media (min-width: 1200px) { @content; }
    }
    @else if $point == wideTabletWidth {
        @media (min-width: 992px) { @content; }
      }
    @else if $point == tabletWidth {
      @media (min-width: 768px) { @content; }
    }
    @else if $point == phoneWidth {
      @media (max-width: 767px)  { @content; }
    }
  }

body{
  color: $text-color;

  @include breakpoint(phoneWidth){
    font-size: 70%;
  }
  @include breakpoint(tabletWidth){
    font-size: 75%;
  }
  @include breakpoint(wideTabletWidth){
    font-size: 80%;
  }
  @include breakpoint(desktopWidth){
    font-size: 100%;
  }
  @include breakpoint(wideDesktopWidth){
    font-size: 125%;
  }
  @include breakpoint(giantDesktopWidth){
    font-size: 150%;
  }
}

//Fonts

@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.eot');
    src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
         url($font-file+'.woff') format('woff'),
         url($font-file+'.woff2') format('woff2'),
         url($font-file+'.ttf') format('truetype'),
         url($font-file+'.svg#'+$font-family) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

// @include font('Kollektif', './assets/fonts/kollektif/Kollektif');
// @include font('Kollektif-Bold', './assets/fonts/kollektif/Kollektif-Bold');
// @include font('Kollektif-Italic', './assets/fonts/kollektif/Kollektif-Italic');
// @include font('Signore', './assets/fonts/signore/Signore');
@include font('Orbitron', './assets/fonts/orbitron/Orbitron-Bold');
@include font('Fraktion', './assets/fonts/fraktion/Fraktion');

@import url('https://fonts.googleapis.com/css2?family=Fraktion:wght@200;300;400&display=swap');