@import url('https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap');

/* Luke Lowrey - Theme Switching https://lukelowrey.com/css-variable-theme-switcher/ */

:root {
  scroll-behavior: smooth;
  -ms-user-select: none; 
      -webkit-user-select: none;
      user-select: none; 
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  overflow-x: hidden;
  background-color: var(--background-color);

  --background-color: #1A1A1A;
  --text-color: #FFFFFF;
  --alt-text-color: #CCCCCC;
  --about-links: #B9FFAD;
}

html[data-theme='light'] {
    --background-color: #fff;
    --text-color: #1a1a1a;
    --alt-text-color: #606060;
    --about-links: #288654;
}

html[data-theme='dark'] {
    --background-color: #1A1A1A;
    --text-color: #FFFFFF;
    --alt-text-color: #CCCCCC;
    --about-links: #B9FFAD;
}

/* @media only screen and (min-width: 768px) { 
  :root{
    cursor: url('../src/assets/images/mouse.svg'), auto;
  }
} */

body {
  margin: 0;
  font-family: "Lora", Arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  padding: 0;
  margin: 0;
}

h1, h2, h3{
  font-weight: 400;
}

a{
  text-decoration: none;
  cursor: none;
}

button{
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}